/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: http://s.buk.io:6200/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzIxMjEyMSIsIj9lcjwjYmNiY2JjIiwiO2VyPCMxNDE0MTR$LCIlPmBePCNmZmQ3NDAiLCI~ZXI8I2ZmZjNjNiIsIjtlcjwjZmZjNzJhfiwid2Fybj5gXjwjZDUyYzQxIiwiP2VyPCNmMmMwYzYiLCI7ZXI8I2M0MWIyYX4sIj9UZXh0PCMyMTIxMjEiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZhZmFmYSIsIjs9PCMyMTIxMjF$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjEyfQ==
*/

@use '~@angular/material' as mat;

@import 'buk-material-palette';

// Compute font config
@include mat.core($typography-config);

// Theme Init
@include mat.all-component-themes($theme);

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: transparent;
}
